@import '../../style/breakpoints.scss';
@import '../../style/colors.scss';

.loader {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.header {
  background-color: $background-light;
  display: flex;
  align-items: center;
  box-shadow: 0px 0.5px 0px rgba(30, 39, 50, 0.12),
    inset 0px -0.5px 0px rgba(30, 39, 50, 0.12);

  .title {
    padding: 26px 40px;

    @media (max-width: $tablet-small) {
      padding: 24px 20px;
    }
  }
}

.body {
  padding: 20px 40px;
  background-color: $background-dark;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  @media (max-width: $laptop) {
    grid-template-columns: 1fr;
  }

  @media (max-width: $tablet-small) {
    padding: 20px;
  }

  .column {
    display: grid;
    grid-template-rows: auto auto;
    align-content: flex-start;
    row-gap: 20px;
  }
}

.docInfo {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;

  @media (min-width: $tablet-small) {
    div:nth-last-child(-n + 1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
  }
}

.tabCard {
  display: flex;
  position: relative;

  .tabHeader {
    transform: translateY(-10px);
    box-shadow: 0px 0.5px 0px rgba(30, 39, 50, 0.12),
      inset 0px -0.5px 0px rgba(30, 39, 50, 0.12);
  }

  .tabBody {
    gap: 10px;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
  }

  .inviteBtn {
    right: 17px;
    top: 10px;
    position: absolute;
    z-index: 2;
    font-weight: 500;
  }

  .counter {
    padding: 2px 8px;
    border-radius: 50%;
    background-color: mix($dark, #fff, 10%);
  }
}

.certificateCard {
  background: $positive-gradient;
  overflow: hidden;
  text-overflow: ellipsis;

  li,
  span,
  header {
    color: white !important;
    border-color: rgba(#fff, 0.2) !important;
    border-width: 2px !important;
  }
  ul {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    li {
      padding: 8px;
      border-radius: 8px;
      background: rgba(30, 39, 50, 0.08);

      display: grid;
      grid-template-columns: 20px auto;
      column-gap: 10px;
      align-items: flex-start;

      .certIcon {
        font-size: 20px;
      }
    }
    .infoCert {
      background-color: transparent;
    }
  }
}

.download {
  display: flex;
  justify-content: flex-end;

  @media (min-width: $tablet-small) {
    width: 40%;
    margin-left: auto;
  }

  .downloadBtn {
    @media (max-width: $tablet-small) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.validationFooterBtn {
  display: flex;
  align-items: center;

  *:last-child {
    padding-left: 10px;
    font-size: 20px;
  }
}
