@import '../../style/colors.scss';
@import '../../style/breakpoints.scss';

.page {
  display: flex;
  justify-content: center;
  flex-grow: 2;
  padding: 32px 40px 0;
  background: $background-dark;
  @media (max-width: $tablet-small) {
    padding: 20px 20px 0;
  }
}

.body {
  flex-grow: 2;
  padding-top: 20px;
  // background: rgba(0, 191, 166, 0.04);
}

.card {
  max-width: 785px !important;
  margin: 0 auto;
}

.description {
  background-color: mix($positive, #fff, 8%);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 12px 20px;

  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: flex-start;

  color: rgba($dark, 0.8);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  .descrIcon {
    padding-right: 16px;
    font-size: 25px;
    color: $positive;
  }
}

.divider {
  margin: 20px 0 !important;
}

.icon {
  color: rgba(0, 191, 166, 0.4);
}
