@import '../../../../../../style/colors.scss';

.stamp {
  min-width: 180px;
  min-height: 65px;
  
  display: grid;
  grid-template-columns: 24px auto;
  column-gap: 8px;
  align-items: flex-start;

  background-color: mix($attention, #FFF, 10%);
  border-radius: 8px;
  padding: 8px;

  .icon {
    font-size: 24px;
    transform: translateY(-7px);
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba($dark, 0.8);
  }
}
