@import '../../../../../../style/colors.scss';
@import '../../../../../../style/breakpoints.scss';

.card {
  gap: 10px;
  padding: 8px;
  display: flex;
  overflow: hidden;
  padding-left: 20px; //????
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 0px 0px 0.5px rgba(30, 39, 50, 0.12),
    inset 0px 0px 0px 0.5px rgba(30, 39, 50, 0.12);
  border-radius: 16px;
}

.sign {
  @extend .card;
  padding: 18px 20px;
}

.waiting {
  @extend .card;
  grid-template-columns: 1fr 10fr 3fr;
}

.active {
  @extend .card;
  background-color: white;

  &.accept {
    background-color: rgba($positive, 0.04);
  }
  &.reject {
    background-color: rgba($warning, 0.04);
  }
  &.waiting {
    background-color: rgba($dark, 0.04);
  }
}

.signee {
  gap: 4px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
}

.signeeDetails {
  display: flex;
  overflow: hidden;
  margin-left: 8px;
  flex-direction: column;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.status {
  gap: 8px;
  display: flex;
  align-items: center;
}

.icon {
  font-size: 24px !important;
}

.date {
  margin-left: 32px;
}

.stamp {
  padding: 10px;
  min-width: 184px;
  margin-left: 52px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  &.accept {
    background-color: rgba($positive, 0.08);
  }
  &.reject {
    background-color: rgba($warning, 0.08);
  }
  &.waiting {
    background-color: rgba($dark, 0.08);
  }
}

.signButtons {
  gap: 12px;
  display: flex;
  min-width: 172px;
  min-height: 40px;
  flex-direction: row;
}

@media (max-width: 640px) {
  .card {
    padding: 8px;
    padding-top: 16px;
  }

  .sign,
  .active {
    flex-direction: column;
  }

  .stamp,
  .signButtons {
    align-self: stretch;
  }

  .signee {
    width: 100%;
    padding-left: 8px;
  }
}
