@import '../../../../../../style/colors.scss';
@import '../../../../../../style/breakpoints.scss';

.formContainer {
  display: flex;
  flex-direction: column;
}

.generalInfoRow {
  display: flex;
  flex-direction: row;

  @media (max-width: $tablet-small) {
    flex-direction: column;
    align-items: center;
  }
}

.generalInfoValues {
  flex-grow: 2;
  display: grid;
  gap: 12px;
  width: 100%;

  @media (min-width: $tablet-small) {
    margin-left: 20px;
    :nth-child(n) {
      grid-column: 1/6;
    }

    :nth-child(2) {
      grid-column: 1/2;
    }

    :nth-child(3) {
      grid-column: 2/6;
    }
  }
}

.companyValues {
  display: grid;
  gap: 12px;

  @media (min-width: $tablet-small) {
    :nth-child(n) {
      grid-column: 1/6;
    }

    :nth-child(3) {
      grid-column: 1/5;
    }

    :nth-child(4) {
      grid-column: 5/6;
    }
  }
}

.certificateValues {
  display: grid;
  gap: 12px;

  @media (min-width: $tablet-small) {
    :nth-child(n) {
      grid-column: 1/6;
    }

    :nth-child(3) {
      grid-column: 1/3;
    }

    :nth-child(4) {
      grid-column: 3/6;
    }
  }
}

.footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;

  @media (min-width: $tablet-small) {
    display: flex;
  }

  .jwtButton {
    grid-column: 1/3;
    background-color: black;
    color: white;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      height: 40px;
      height: 40px;
    }

    @media (min-width: $tablet-small) {
      justify-content: flex-start;
      font-size: 16px;
      width: 180px;
    }
  }

  .button {
    display: flex;
    justify-content: center;

    *:last-child {
      padding-left: 10px;
    }

    @media (min-width: $tablet-small) {
      height: 80%;
    }
  }

  .firstButton {
    @media (min-width: $tablet-small) {
      margin-left: auto;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
}

.accepted {
  background-color: mix($positive, #fff, 8%) !important;
}

.rejected {
  background-color: mix($warning, #fff, 8%) !important;
}
