@import './style/colors.scss';
@import './style/breakpoints.scss';

@import '~@fontsource/roboto/index.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';

h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  margin: 0 !important;
}

* {
  font-family: 'Roboto', sans-serif;
}

::selection {
  color: #fff !important;
  background-color: $positive !important;
}

@media (max-width: $tablet-large) {
  .ant-scrolling-effect {
    min-width: 100% !important;
    header:first-of-type {
      margin-right: 17px;
    }
  }
}

@media (min-width: $tablet-large) {
  .ant-scrolling-effect {
    min-width: 100% !important;
    padding-right: 17px !important;
    // background-color: rgba($positive, 0.3) !important;
    header:first-of-type {
      padding-right: 37px;
    }
  }
}
