.title {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.icon {
  margin-left: 8px;
}
