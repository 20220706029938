@import '../../style/colors.scss';

.layout {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;

  main {
    width: 100%;
    flex-grow: 1;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    background-color: $background-dark;

    .footer {
      justify-self: flex-end;
    }
  }
}

.column {
  flex-direction: column;
}
