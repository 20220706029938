@import '../../../../../../style/colors.scss';

.stamp {
  min-width: 180px;
  min-height: 65px;

  display: grid;
  grid-template-columns: 24px auto;
  column-gap: 8px;

  padding: 8px;
  border-radius: 8px;

  .icon {
    font-size: 24px;
    transform: translateY(-10px);
  }

  .header {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    align-items: center;
    width: 100%;

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: rgba($dark, 0.8);
    }

    // details
    span {
      color: #007aff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        transform: scale(1.01);
      }
    }

    // date
    small {
      grid-column: 1/3;
      font-size: 12px;
      line-height: 16px;
      color: rgba($dark, 0.4);
    }
  }
}

.accept {
  background-color: mix($positive, #fff, 8%);
  p {
    padding-right: 0.7rem;
  }
}

.reject {
  background-color: mix($warning, #fff, 8%);
}
